import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useColorModeValue
} from "@chakra-ui/react";
import { MdAdd, MdDelete } from "react-icons/md";
import { Assistant } from "../../gen-ts/ai/assistants/v0/assistant_pb";
import isEmail from 'validator/es/lib/isEmail';
import isFQDN from 'validator/es/lib/isFQDN';
import { useUserState } from "../../state/user";
import { useTenantInfo } from "../../hooks/useTenantInfo";



interface Props {
  assistant: Assistant;
  onUpdate: (assistant: Assistant) => void;
}

const TabAssistantUsers: React.FC<Props> = ({ assistant, onUpdate }) => {
  const [ domains, setDomains ] = useState<string[]>([]);
  const [ users, setUsers ] = useState<string[]>([]);
  const userState = useUserState();

  const [ dialogOpen, setDialogOpen ] = useState(false);

  const [ dialogType, setDialogType ] = useState<'user' | 'domain'>('domain');

  const isOwner = assistant.owners.includes(`user:${userState.user?.email}`);

  const { isAdmin} = useTenantInfo();


  useEffect(() => {
    const _domains: string[] = [];
    const _users: string[] = [];

    for (const owner of (assistant.owners || [])) {
      const [ type, name ] = owner.split(':');

      if (type === 'domain') {
        _domains.push(`${name}#owner`);
      }
      else {
        _users.push(`${name}#owner`);
      }
    }

    for (const owner of (assistant.uploaders || [])) {
      const [ type, name ] = owner.split(':');

      if (type === 'domain') {
        _domains.push(`${name}#uploader`);
      }
      else {
        _users.push(`${name}#uploader`);
      }
    }

    for (const owner of (assistant.viewers || [])) {
      const [ type, name ] = owner.split(':');

      if (type === 'domain') {
        _domains.push(`${name}#viewer`);
      }
      else {
        _users.push(`${name}#viewer`);
      }
    }

    _domains.sort();
    _users.sort();

    setDomains(_domains);
    setUsers(_users);
    //eslint-disable-next-line
  }, [ assistant ]);

  const onClose = () => setDialogOpen(false);

  const openAddUserDialog = () => {
    setDialogType('user');
    setDialogOpen(true);
  };

  const openAddDomainDialog = () => {
    setDialogType('domain');
    setDialogOpen(true);
  };

  const ucFirst = (str: string) => {
    return str[ 0 ].toUpperCase() + str.slice(1);
  }

  const removeItem = (name: string, role: string) => {
    const updated = assistant.clone();
    if (role === 'owner') {
      updated.owners = updated.owners.filter((owner) => owner !== name);
    }
    else if (role === 'uploader') {
      updated.uploaders = updated.uploaders.filter((uploader) => uploader !== name);
    }
    else {
      updated.viewers = updated.viewers.filter((viewer) => viewer !== name);
    }

    onUpdate(updated);
  }


  return (
    <>
      <VStack bgColor={useColorModeValue('gray.100', 'gray.700')} p={4} borderRadius={10} align="start">
        <HStack width="100%" mb={3}>
          <Text flexGrow={1} fontWeight="600">Domains</Text>
          <IconButton
            icon={<MdAdd />}
            aria-label="add new domain"
            colorScheme="primary"
            color="black"
            onClick={openAddDomainDialog}
            isDisabled={!isOwner && !isAdmin()}
          />
        </HStack>

        <TableContainer bgColor={useColorModeValue('white', 'gray.800')} width="100%" borderRadius={10} whiteSpace="wrap">
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Domain</Th>
                <Th>Role</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                domains.map((domain, index) => {
                  const [ name, role ] = domain.split('#');
                  return (
                    <Tr key={index}>
                      <Td whiteSpace="nowrap" width="50%">{name}</Td>
                      <Td whiteSpace="nowrap" width="50%">{ucFirst(role)}</Td>
                      <Td>
                        <IconButton
                          icon={<MdDelete />}
                          aria-label="Delete"
                          variant="ghost"
                          onClick={() => removeItem(`domain:${name}`, role)}
                          isDisabled={
                            !isOwner || (role === 'owner' && assistant.owners.length === 1)
                          }
                        />
                      </Td>
                    </Tr>
                  );
                })
              }
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>

      <VStack bgColor={useColorModeValue('gray.100', 'gray.700')} p={4} borderRadius={10} align="start" mt={5}>
        <HStack width="100%" mb={3}>
          <Text flexGrow={1} fontWeight="600">Users</Text>
          <IconButton
            icon={<MdAdd />}
            aria-label="add new user"
            colorScheme="primary"
            color="black"
            onClick={openAddUserDialog}
            isDisabled={!isOwner && !isAdmin()}
          />
        </HStack>

        <TableContainer bgColor={useColorModeValue('white', 'gray.800')} width="100%" borderRadius={10} whiteSpace="wrap">
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Email</Th>
                <Th>Role</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>

              {
                users.map((user, index) => {
                  const [ name, role ] = user.split('#');
                  return (
                    <Tr key={index}>
                      <Td whiteSpace="nowrap" width="50%">{name}</Td>
                      <Td whiteSpace="nowrap" width="50%">{ucFirst(role)}</Td>
                      <Td>
                        <IconButton
                          icon={<MdDelete />}
                          aria-label="Delete"
                          variant="ghost"
                          onClick={() => removeItem(`user:${name}`, role)}
                          isDisabled={
                            !isOwner || (role === 'owner' && assistant.owners.length === 1)
                          }
                        />
                      </Td>
                    </Tr>
                  );
                })
              }

            </Tbody>
          </Table>
        </TableContainer>
      </VStack>

      <AddRoleDialog
        type={dialogType}
        isOpen={dialogOpen}
        onClose={onClose}
        onSelected={(role) => {
          const updated = assistant.clone();
          const [ name, roleType ] = role.split('#');
          if (roleType === 'owner') {
            updated.owners.push(`${dialogType}:${name}`);
          }
          else if (roleType === 'uploader') {
            updated.uploaders.push(`${dialogType}:${name}`);
          }
          else {
            updated.viewers.push(`${dialogType}:${name}`);
          }

          onUpdate(updated);
        }}
      />
    </>
  );
};

export default TabAssistantUsers;


interface AddRoleDialogProps {
  type: 'user' | 'domain';
  isOpen: boolean;
  onClose: () => void;
  onSelected: (role: string) => void;
}

const AddRoleDialog: React.FC<AddRoleDialogProps> = ({ type, isOpen, onClose, onSelected }) => {
  const initialRef = useRef(null);
  const dialogInputLabel = type === 'user' ? 'Email' : 'Domain';
  const [ name, setName ] = useState('');
  const [ role, setRole ] = useState('');
  const [ error, setError ] = useState('');

  const onSave = () => {
    let errMessage = '';
    if (type === 'user') {
      const valid = isEmail(name);
      if (!valid) {
        errMessage = 'Invalid Email';
      }
    }
    else {
      const valid = isFQDN(name);
      if (!valid) {
        errMessage = 'Invalid Domain';
      }
    }

    if (Boolean(errMessage)) {
      setError(errMessage);
      return;
    }

    onSelected(`${name}#${role}`);
    close();
  }

  const close = () => {
    setError('');
    setName('');
    setRole('');
    onClose();
  }

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={isOpen}
      onClose={close}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {
            type === 'user' ? 'Add User' : 'Add Domain'
          }
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl isInvalid={Boolean(error)}>
            <FormLabel>
              {dialogInputLabel}
            </FormLabel>
            <Input
              ref={initialRef}
              placeholder={dialogInputLabel}
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <FormErrorMessage>{error}</FormErrorMessage>
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Role</FormLabel>
            <Select
              defaultValue={role}
              onChange={(event) => {
                setRole(event.target.value);
              }}>
              <option value='' disabled>Select a role</option>
              <option value='viewer'>Viewer</option>
              <option value='uploader'>Uploader</option>
              {
                type === 'user' && (
                  <option value='owner'>Owner</option>
                )
              }
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme='primary'
            mr={3}
            color="black"
            isDisabled={!Boolean(name) || !Boolean(role)}
            onClick={onSave}
          >
            Save
          </Button>
          <Button onClick={close}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}